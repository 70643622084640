jQuery(function() {
    FastClick.attach(document.body);
});
jQuery(document).ready(function($) {
  var scrollposition = 0;
  var saveposition = 0;

  console.log('ready');

  $(window).scroll(function(){
    if ($(this).scrollTop() > 0) {
      $('.page-header').addClass('scrolled');
    } else {
      $('.page-header').removeClass('scrolled');
    }
  });

  $('.touch-menu-open').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    saveposition = $(window).scrollTop();
    console.log('saveposition = ' + saveposition);
    $('html, body').animate({scrollTop: 0}, 0);
    $('.touch-menu').addClass('active');
    $('.page-header').addClass('menu-active');
  });

  $('.touch-menu-close').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    $('html, body').animate({scrollTop: saveposition}, 0);
    $('.touch-menu').removeClass('active');
    $('.page-header').removeClass('menu-active');
  });

  /*$('.accordion-heading').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    $(this).parent().toggleClass('active');
  });*/

  // $('.memberLogin').click(function(e) {
  //   e.preventDefault();
  //   $('html, body').animate({scrollTop: saveposition}, 0);
  //   $('.touch-menu').removeClass('active');
  //   $('.page-header').removeClass('menu-active');

  //   if ($('.login-bar').hasClass('active')) {
  //     $('body').removeClass('login-active');
  //     $('.login-bar').removeClass('active');
  //   } else {
  //     $('body').addClass('login-active');
  //     $('.login-bar').addClass('active');
  //   }
  // });

  // $('.login-bar a.cancel').click(function(e) {
  //   e.preventDefault();
  //   $('body').removeClass('login-active');
  //   $('.login-bar').removeClass('active');
  // });
});